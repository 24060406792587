<template>
  <v-app v-if="me">
    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!--<default-settings />-->
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'DefaultLayout',

    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultFooter: () => import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
      // DefaultSettings: () => import(
      //   /* webpackChunkName: "default-settings" */
      //   './Settings'
      // ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },

    computed: {
      ...mapGetters({
        me: 'auth/getMe',
      }),
    },

    created () {
      this.$store.dispatch('auth/fetchMe').catch(() => {
        this.$router.push({ name: 'Login' })
      });
    },
  }
</script>
